<template>
	<main class="main">
		<div class="container">
			<Crumbs :data="crumbs"></Crumbs>
		</div>

		<section class="main__box">
			<div class="container container--small">
				<h1 class="main__title main__title--search mb-0" v-if="searchList">
					<ul>
						<li>Результаты поиска для&nbsp;</li>
						<li>"</li>
						<li>{{ searchList.query }}</li>
						<li>"</li>
					</ul>
				</h1>
				<h1 class="main__title main__title--search mb-0" v-else>
					Введите запрос для поиска
				</h1>
				<template v-if="searchList">
					<p class="main__text main__text--small editor">
						Найдено совпадений:
						{{ searchList.total == undefined ? '0' : searchList.total }}
					</p>

					<template v-if="searchList.data.length > 0">
						<ul class="news__list">
							<li
								class="news__item news__search--item"
								v-for="(item, index) in searchList.data"
								:key="index"
							>
								<template
									v-if="
										link({ link: item.url, type: item.type }).type == 'material'
									"
								>
									<button
										@click.prevent="
											getMaterial(link({ link: item.url, type: item.type }).url)
										"
										class="news__link"
									>
										<div class="news__content">
											<h3 class="news__title" v-html="item.title"></h3>
											<div
												class="news__text editor"
												v-html="clearJson(item.description)"
											></div>
										</div>
									</button>
								</template>
								<template v-else>
									<router-link
										:to="link({ link: item.url, type: item.type }).url"
										class="news__link"
									>
										<div class="news__content">
											<h3 class="news__title" v-html="item.title"></h3>
											<div
												class="news__text editor"
												v-html="clearJson(item.description)"
											></div>
										</div>
									</router-link>
								</template>
							</li>
						</ul>

						<div
							class="news__show-more flex flex--center"
							v-if="pagination.pageCount != pagination.currentPage"
						>
							<button class="button" type="button" @click.prevent="nextPage">
								Показать ещё
							</button>
						</div>
					</template>

					<template v-else>
						<p class="main__text">Ничего не найдено</p>
					</template>
				</template>
			</div>
		</section>
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs'

export default {
	name: 'Search',
	components: {
		Crumbs,
	},

	data() {
		return {
			crumbs: null,
		}
	},

	methods: {
		clearJson(text) {
			if (text && (text.includes('{') || text.includes('}'))) {
				let a = text.split(':"')
				return a[a.length - 1]
			} else {
				return text
			}
		},
		link(data) {
			let url = data.link
			let type = data.type
			let arr = null,
				alias = null

			switch (type) {
				case 'page':
					arr = url.split('/')
					alias = arr[arr.length - 1]

					url = `/holder/capabilities/${alias}`
					break

				case 'news':
					arr = url.split('/')
					alias = arr[arr.length - 1]

					url = `/holder/news/${alias}`
					break

				case 'faq':
					url = `/holder/support/questions`
					break

				case 'material':
					arr = url.split('/')
					alias = arr[arr.length - 1]

					url = alias
					break

				default:
					url = data.link
					break
			}

			return {
				url,
				type,
			}
		},

		getMaterial(id) {
			this.$store.dispatch('GET_MATERIAL', id)
		},

		nextPage() {
			let page = this.pagination.currentPage
			page++

			this.$store.dispatch('GET_SEARCH', {
				query: this.searchList.query,
				perPage: 10,
				currentPage: page,
			})
		},
	},

	computed: {
		searchList() {
			return this.$store.getters.SEARCH_RESULT
		},

		pagination() {
			const search = this.$store.getters.SEARCH_RESULT

			return {
				pageCount: search.pageCount,
				currentPage: search.currentPage,
			}
		},
	},

	mounted() {
		this.crumbs = [
			{
				link: 'HolderHome',
				text: 'Главная',
			},
			{
				link: null,
				text: 'Поиск',
			},
		]
         
		setTimeout(()=>{
			if(this.searchList == null) this.$router.push({ path: '/holder'})
		}, 500)
	},
}
</script>
